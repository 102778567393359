import React from "react"
import { Link } from "gatsby"

const selectAll = function(e){ 
	if(typeof e !== 'undefined' && e.target.focus) e.target.focus(); 
	if(typeof e !== 'undefined' && e.target.select) e.target.select(); 
}
const codeOfferTemplate = '<script>\n'+
	'!(function (w, d) {\n' +
	'w.Flickerlessly = w.Flickerlessly || {}, function (t) { "use strict"; var i = function (t, n, a, o) { var i = "atNodeInserted" + t, r = [], e = ["", "-moz-", "-webkit-", "-ms-", "-o-"]; e.forEach(function (t, e) { r.push("@" + t + "keyframes " + i + " {from {opacity:0.99} to {opacity:1}}") }), r.push(n + "{"), e.forEach(function (t, e) { r.push(t + "animation-duration:0.001s;" + t + "animation-name:" + i + ";") }), r.push("}"); var s = d.getElementsByTagName("head")[0]; if (s) { var c = d.createElement("style"); c.setAttribute("type", "text/css"), c.styleSheet ? c.styleSheet.cssText = r.join("\\n") : c.appendChild(d.createTextNode(r.join("\\n"))), s.insertBefore(c, s.firstChild) } var l = function (t) { if (t.animationName === i && "object" == typeof t.target) { var e = !0 === o || !1 === o && null === t.target.getAttribute("data-flk-success"); u("(\'" + n + "\') ready! Execute: " + e, t.target), "function" == typeof a && e ? (t.target.setAttribute("data-flk-success", i), a(t.target)) : u("Won\'t Callback", e, a) } };["animationstart", "MSAnimationStart", "webkitAnimationStart"].forEach(function (t, e) { d.addEventListener(t, l, !1) }) }, u = function () { Array.prototype.unshift.call(arguments, "FLK:"), console.info.apply(this, arguments) }, r = Math.floor(1e3 * Math.random() + 1); t.onReady = function () { for (var t = 0; t < arguments.length; t++) { var e = arguments[t], n = e.selector, a = e.success || null, o = e.persist || !1; i(r++, n, a, o) } } }(Flickerlessly);\n'	+
		'Flickerlessly.onReady({\n' +
			' selector: ".a",\n' +
			' success: function (el) {\n' +
				'  console.log("Element found!", el)\n' +
			' }\n' +
		'}\n' +
		');\n' +
	'})(window, document);\n' +
	'</script>';
const codeCustomEvents = 'document.addEventListener("at-library-loaded", function(e) {'+
	' console.log("atNotification at-library-loaded",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-request-start", function(e) {\n' +
	' console.log("atNotification at-request-start",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-request-succeeded", function(e) {\n' +
	' console.log("atNotification at-request-succeeded",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-request-failed", function(e) {\n' +
	' console.log("atNotification at-request-failed",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-content-rendering-start", function(e) {\n' +
	' console.log("atNotification at-content-rendering-start",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-content-rendering-succeeded", function(e) {\n' +
	' console.log("atNotification at-content-rendering-succeeded",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-content-rendering-redirect", function(e) {\n' +
	' console.log("atNotification at-content-rendering-redirect",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-content-rendering-no-offers", function(e) {\n' +
	' console.log("atNotification at-content-rendering-no-offers",e.detail);\n' +
	'}, false),\n' +
	'document.addEventListener("at-content-rendering-failed", function(e) {\n' +
	' console.log("atNotification at-content-rendering-failed",e.detail);\n' +
	'}, false)';
const codeReplaceNodes = 'var replaceNodes = function(refNode, newNode){\n'+
	' refNode.parentNode.replaceChild(newNode, refNode);\n'+
    '};';
const codeInsertNode = 'var insertNode = function(refNode, newNode){\n'+
    ' refNode.parentNode.insertBefore(newNode, refNode);\n'
    '};';
const codeInsertAfterNode = 'var insertAfterNode = function(refNode, newNode){\n'+
	' refNode.parentNode.insertBefore(newNode, refNode.nextSibling);\n'+
    '};';
const codeAppendNode = 'var appendNode = function(refNode, newNode){\n'+
    ' refNode.appendChild(newNode);\n'+
    '};';
const codePrependNode = 'var prependNode = function(refNode, newNode){\n'+
    ' refNode.insertBefore(newNode, refNode.childNodes[0]);\n'+
	'};';
const codeInjectCSS = 'var cssRules = [];\n'+
'cssRules.push(".at-hidden{visibility:hidden}");\n'+
'var applyCSS = function(css){\n'+
'var head = document.getElementsByTagName("head")[0];\n'+
'if (head) {\n'+
	' var style = document.createElement("style");\n'+
	' style.setAttribute("type", "text/css");\n'+
	' if (style.styleSheet)\n'+
		'  style.styleSheet.cssText = css.join("\\n");\n'+
	' else\n'+
		'  style.appendChild(document.createTextNode(css.join("\\n")));\n'+
	' head.insertBefore(style, head.firstChild)\n'+
'};\n'+
'}\n'+
'applyCSS(cssRules);';
const codeCustomLog = 'const atlog = (window.location.href.indexOf("Debug=1")!==-1) ? \n'+
' function () { Array.prototype.unshift.call(arguments, "AT-LOG:"); console.info.apply(this, arguments); } :\n'+
' function () {};';
const codeGetOfferClassic = 'adobe.target.getOffer({\n'+  
	' "mbox": "target-global-mbox",\n'+
	' "params": {\n'+
	' "a": 1,\n'+
	' "b": 2,\n'+
	' "profile.age": 27,\n'+
	' "profile.gender": "male"\n'+
	'},\n'+
	'"success": function(offer) {\n'+      
	' adobe.target.applyOffer({\n'+
	'  "mbox": "target-global-mbox",\n'+
	'  "offer": offer \n'+
	' });\n'+
	'},\n'+  
	'"error": function(status, error){\n'+       
	' console.log("Error", status, error);\n'+
	'}\n'+
	'});';
const codeGetOfferES6 = 'adobe.target.getOffer({\n'+  
	'mbox: "target-global-mbox",\n'+
	'params: { a: 1, b: 2 },\n'+
	'success: (response) => {\n'+
	'adobe.target.applyOffer({ \n'+
	' mbox: "target-global-mbox",\n'+
	' offer: offer \n'+
	'});\n'+
	'},\n'+ 
	'error: (status, error) => {\n'+          
	'console.log("Error", status, error);\n'+
	'}\n'+
	'});';
const codeGetOffersEPL = 'adobe.target.getOffers({\n'+
	' request: {\n'+
	'  execute: {\n'+
	'   pageLoad : {}\n'+
	'  }\n'+
	' }\n'+
	'}).then(function(response) {\n'+
	' //console && console.log("Target Page Load Response", response);\n'+
	' adobe.target.applyOffers({ \n'+
	'  response: response \n'+
	' });\n'+
	'});';
const codeCustomEventDispatch = 'var evt=new CustomEvent("event-view-start", {"detail":{\n'+
	' mbox: "target-global-mbox",\n'+
	' params: {param1: "string", param2: 123}\n'+
	'}});\n'+
	'document.body.dispatchEvent(evt);';
const codeCustomEventListening = 'document.body.addEventListener("event-view-start", function(e) {\n'+
	'var mbox = e.detail.mbox || "target-global-mbox";\n'+
	'var params = e.detail.params || {};';
const codeGetElTopPos = 'var getElementPositionCoords = function(el){\n'+
	' var bodyRect = document.body.getBoundingClientRect(),\n'+
		' elemRect = el.getBoundingClientRect(),\n'+
		' offsetY  = elemRect.top - bodyRect.top,\n'+
		' offsetX  = elemRect.left - bodyRect.left;\n'+
	' return {left:offsetX, top:offsetY};\n'+
	'}';
const codeVisitorResetState = 'var visitor = Visitor.getInstance("EB9CAE8B56E003697F000101@AdobeOrg");\n'+
'if(typeof visitor.resetState === "function"){\n'+
' visitor.resetState();\n'+
'}';


const codeAtConfig = 'window.adobe.target.init(window, document, {\n'+
	' "clientCode": "demo",\n'+
	' "imsOrgId": "",\n'+
	' "serverDomain": "localhost:5000",\n'+
	' "timeout": 2000,\n'+
	' "globalMboxName": "target-global-mbox",\n'+
	' "version": "2.0.0",\n'+
	' "defaultContentHiddenStyle": "visibility: hidden;",\n'+
	' "defaultContentVisibleStyle": "visibility: visible;",\n'+
	' "bodyHiddenStyle": "body {opacity: 0 !important}",\n'+
	' "bodyHidingEnabled": true,\n'+
	' "deviceIdLifetime": 63244800000,\n'+
	' "sessionIdLifetime": 1860000,\n'+
	' "selectorsPollingTimeout": 5000,\n'+
	' "visitorApiTimeout": 2000,\n'+
	' "overrideMboxEdgeServer": false,\n'+
	' "overrideMboxEdgeServerTimeout": 1860000,\n'+
	' "optoutEnabled": false,\n'+
	' "optinEnabled": false,\n'+
	' "secureOnly": false,\n'+
	' "supplementalDataIdParamTimeout": 30,\n'+
	' "authoringScriptUrl": "//cdn.tt.omtrdc.net/cdn/target-vec.js",\n'+
	' "urlSizeLimit": 2048,\n'+
	' "endpoint": "/rest/v1/delivery",\n'+
	' "pageLoadEnabled": true,\n'+
	' "viewsEnabled": true,\n'+
	' "analyticsLogging": "server_side",\n'+
	' "serverState": {},\n'+
	' "decisioningMethod": "server-side",\n'+
	' "legacyBrowserSupport":  false\n'+
'}';
const codeAlloyConfig = 'alloy("configure", {\n'+
    ' edgeDomain: undefined,\n'+
    ' edgeConfigId: "7f256d21-f4ee-473e-8e66-1fb57f32c0c4:prod",\n'+
    ' orgId: "97D1F3F459CE0AD80A495CBE@AdobeOrg",\n'+
    ' debugEnabled: true,\n'+
    ' prehidingStyle: "body { opacity: 0 !important }"\n'+
'});';
const codeAtExecPageLoad = 'adobe.target.getOffer({\n'+
	' mbox: "target-global-mbox",\n'+
	' success: console.log,\n'+
	' error: console.error\n'+
	'});\n'+
	'adobe.target.getOffers({\n'+
	' request: {\n'+
	'  execute: {\n'+
	'   pageLoad: {}\n'+
	'  }\n'+
	' }\n'+
	'})\n'+
	'.then(console.log)\n'+
	'.catch(console.error);\n';
const codeAlloyExecPageLoad = 'alloy("sendEvent", {\n'+
	' renderDecisions: true, // this will enable auto rendering of the Target VEC activities\n'+
	' xdm: ...,\n'+
	' data: ...\n'+
	'})\n'+
	'.then(console.log)\n'+
'.catch(console.error);';
const codeAtMbox = 'adobe.target.getOffer({\n'+
	' mbox: "hero-banner",\n'+
	' success: console.log,\n'+
	' error: console.error\n'+
	'});\n'+
	'adobe.target.getOffers({\n'+
	' request: {\n'+
	'  execute: {\n'+
	'   mboxes: [\n'+
	'   {\n'+
	'    index: 0,\n'+
	'    name: "hero-banner"\n'+
	'   }]\n'+
	'  }\n'+
	' }\n'+
	'})\n'+
	'.then(console.log)\n'+
'.catch(console.error);';
const codeAlloyScope = 'alloy("sendEvent", {\n'+
	' decisionScopes: ["hero-banner"],\n'+
	' data: ...,\n'+
	' xdm: ...\n'+
	'})\n'+
	'.then(console.log)\n'+
'.catch(console.error);';
const codeAtTrackEvent = 'adobe.target.trackEvent({ \n'+
	' "type": "click",\n'+
	' "mbox": "some-mbox"\n'+
	'});\n'+
	'adobe.target.sendNotifications({ \n'+
	' request: {\n'+
	'  notifications: [{\n'+
	'   ...\n'+
	'   mbox: {\n'+
	'    name: "some-mbox"\n'+
	'   },\n'+
	'   type: "click",\n'+
	'   ...\n'+
	'  }]\n'+
	' }\n'+
'});';
const codeAlloyTrackEvent = 'alloy("sendEvent", {\n'+
	' xdm:{\n'+
	'  eventType: "display",\n'+
	'  _experience: {\n'+
	'   decisioning: {\n'+
	'    propositions: [\n'+
	'     {\n'+
	'       id: "AT:eyJhY3Rpdml0eUlkIjoiMzY3NzAyIiwiZXhwZXJpZW5jZUlkIjoiMCJ9",\n'+
	'       scope: "__view__",\n'+
	'       scopeDetails: ...\n'+
	'     }\n'+
	'    ]\n'+
	'   }\n'+
	'  }\n'+
	'})\n'+
	'.then(console.log)\n'+
'.catch(console.error)';
const codeAtTriggerView = 'adobe.target.triggerView("homeView")';
const codeAlloyTriggerView = 'alloy("sendEvent", {\n'+
	' renderDecisions: true,\n'+
	' xdm:{\n'+
	'  web:{\n'+
	'	 webPageDetails:{\n'+
	'     viewName: viewName\n'+
	'    }\n'+
	'  }\n'+
	' }\n'+
	'}).then(result => {\n'+
	'console.log("here are the decisions that were not rendered: ", result.decisions);\n'+
	'console.log("here are the propositions: ", result.propositions);\n'+
'});';
const codeAtPrefetchViews = 'adobe.target.getOffers({\n'+
	' request: {\n'+
	'  prefetch: {\n'+
	'   views: [{}]\n'+
	'  }\n'+
	' }\n'+
	'})\n'+
	'.then(console.log)\n'+
'.catch(console.error);';
const codeAlloyPrefetchViews = 'alloy("sendEvent", {\n'+
	' renderDecisions: false,\n'+
	' decisionScopes: ["__view__"],\n'+
	' data: ...,\n'+
	' xdm: ...\n'+
	'})\n'+
	'.then(console.log)\n'+
'.catch(console.error);';

const ServicesContent = () => (

	<div className="container ng-scope">
	    <div className="row">
	        <div className="col-lg-12">
	            <h1 className="pb-2 mt-4 mb-2 border-bottom">Services</h1>
	        </div>
	    </div>


		<div className="row">

			<div className="col-lg-12 text-center">
				<h2>HTML Offer Builder</h2>
				<hr/>
			</div>

			<div className="col-lg-6 col-md-6">
				<h4>
					Offer Builder Default Template
				</h4>
				<textarea className="code" wrap="virtual" readonly="" 
					value={codeOfferTemplate} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
				Notifications for at.js
				</h4>
				<textarea className="code" wrap="virtual" readonly="" 
					value={codeCustomEvents} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Replace a Node
				</h4>
				<textarea className="code small" wrap="virtual" readonly="" 
					value={codeReplaceNodes} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Insert a Node
				</h4>
				<textarea className="code small" wrap="virtual" readonly="" 
					value={codeInsertNode} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
                <h4>
                    Insert After a Node
                </h4>
                <textarea className="code small" wrap="virtual" readonly=""  
                    value={codeInsertAfterNode} 
                    onClick={selectAll}>
	            </textarea>
			</div>
			<div className="col-lg-6 col-md-6">
                <h4>
                    Append a Node
                </h4>
                <textarea className="code small" wrap="virtual" readonly=""  
                    value={codeAppendNode}
                    onClick={selectAll}>
                </textarea>
			</div>
			<div className="col-lg-6 col-md-6">
                <h4>
                    Prepend a Node
                </h4>
                <textarea className="code small" wrap="virtual" readonly=""  
					value={codePrependNode} 
					onClick={selectAll}>
                </textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Inject CSS Dynamically
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeInjectCSS} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Custom Log
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeCustomLog} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Legacy getOffer
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeGetOfferClassic} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					getOffer ES6-Style
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeGetOfferES6} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Custom Event - Dispatching
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeCustomEventDispatch} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Custom Event - Listening
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeCustomEventListening} 
					onClick={selectAll}>                  
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Get Element Top Position
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeGetElTopPos} 
					onClick={selectAll}>                  
				</textarea>
			</div>
			

			<div className="col-lg-6 col-md-6">
				<h4>
					Page Load Request (aka TGM)
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeGetOffersEPL} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>
					Visitor resetState()
				</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeVisitorResetState} 
					onClick={selectAll}>
				</textarea>
			</div>



			<div className="col-lg-12 text-center">
				<h2>AT.JS to ALLOY.JS Functions Mapping</h2><hr/>
			</div>

			<div className="col-lg-6 col-md-6">
				<h4>Configuration in at.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAtConfig} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>Configuration in alloy.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAlloyConfig} 
					onClick={selectAll}>
				</textarea>
			</div>

			<div className="col-lg-6 col-md-6">
				<h4>Page Load Event in at.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAtExecPageLoad} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>Page Load Event in alloy.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAlloyExecPageLoad} 
					onClick={selectAll}>
				</textarea>
			</div>

			<div className="col-lg-6 col-md-6">
				<h4>Regional Mbox in at.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAtMbox} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>Decision Scope in alloy.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAlloyScope} 
					onClick={selectAll}>
				</textarea>
			</div>

			<div className="col-lg-6 col-md-6">
				<h4>Track Event in at.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAtTrackEvent} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>Track Event in alloy.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAlloyTrackEvent} 
					onClick={selectAll}>
				</textarea>
			</div>


			<div className="col-lg-6 col-md-6">
				<h4>Prefetch Views in at.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAtPrefetchViews} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>Prefetch Views in alloy.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAlloyPrefetchViews} 
					onClick={selectAll}>
				</textarea>
			</div>

			<div className="col-lg-6 col-md-6">
				<h4>Trigger View in at.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAtTriggerView} 
					onClick={selectAll}>
				</textarea>
			</div>
			<div className="col-lg-6 col-md-6">
				<h4>Trigger View in alloy.js</h4>
				<textarea className="code small" wrap="virtual" readonly=""  
					value={codeAlloyTriggerView} 
					onClick={selectAll}>
				</textarea>
			</div>

		</div>
	</div>

)

export default ServicesContent
