import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import ServicesContent from "../components/content/services"
import Footer from "../components/footer"

const ServicesPage = () => (
  <>
    <SEO title="Services" />
    <Nav />
    <ServicesContent />
    <Footer />
  </>
)

export default ServicesPage
